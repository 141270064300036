import React from "react";
import { signIn } from "next-auth/client";

import SignIn from "@andertons/design-system/components/SignIn.jsx";

import LoginPageLayout from "../components/layouts/LoginPage";
import StatusError from "../utils/StatusError";

LoginPage.Layout = LoginPageLayout;

export default function LoginPage() {
  const [error] = React.useState(null);

  if (error) {
    const { statusText, status } = error.response || {};
    const err = new StatusError(statusText, status);
    throw err;
  }

  return ( 
    <div className="w-full h-full flex items-center justify-center">
      <SignIn onClick={() => signIn("google", { callbackUrl: '/' })} key="login" />
    </div>
  );
}